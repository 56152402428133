const config = {
    siteTitle: "Ashwangada stress-control", // Site title.
    siteTitleShort: "Ashwangada", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    siteTitleAlt: "Ashwangada stress-control", // Alternative site title for SEO.
    siteLogo: "/static/img/og-image.png", // Logo used for SEO and manifest.
    siteUrl: "https://stress-control.pl", // Domain of your website without pathPrefix.
    pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
    siteDescription: "Stress Control Ashwagandha to suplement diety o wszechstronnym działaniu polecany dla osób aktywnych fizycznie i umysłowo. Ashwagandha, nazywana także indyjskim żeń-szeniem, pozwala na komfortowe funkcjonowanie w warunkach codziennego stresu oraz utrzymanie stabilności emocjonalnej.", // Website description used for RSS feeds/meta description tag.
    siteFBAppID: "1201789843335914", // FB Application ID for using app insights
    googleAnalyticsID: "UA-144512872-1", // GA tracking ID.
    postDefaultCategoryID: "Zdrowie", // Default category for posts.
    dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
    dateFormat: "DD/MM/YYYY", // Date format for display.
    userName: "Krotex sp.zoo", // Username to display in the author segment.
    userEmail: "poczta@krotex.com.pl", // Email used for RSS feed's author segment
    userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
    userLocation: "Warszawa, Polska", // User location to display in the author segment.
    copyright: "Copyright © 2019. Ashwanganda, Krotex sp.zoo ", // Copyright string for the footer of the website and RSS feed.
    themeColor: "#c62828", // Used for setting manifest and progress theme colors.
    backgroundColor: "#e0e0e0" // Used for setting manifest background color.
  };
  
  // Validate
  
  // Make sure pathPrefix is empty if not needed
  if (config.pathPrefix === "/") {
    config.pathPrefix = "";
  } else {
    // Make sure pathPrefix only contains the first forward slash
    config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
  }
  
  // Make sure siteUrl doesn't have an ending forward slash
  if (config.siteUrl.substr(-1) === "/")
    config.siteUrl = config.siteUrl.slice(0, -1);
  
  // Make sure siteRss has a starting forward slash
  if (config.siteRss && config.siteRss[0] !== "/")
    config.siteRss = `/${config.siteRss}`;
  
  module.exports = config;