import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Slider from "react-slick";


const Item = ({ title, image, link, excerpt, date }) => {
  return(
<article key={title} className="otherPosts PostCard">
<BackgroundImage Tag="div" className="wrapperPosts"
                           fluid={image.fluid}
                           backgroundColor={`#040e18`}
          >
      <div className="dataPosts">
        <div className="contentPosts">
          <span className="authorPosts">Krotex</span>
          <h1 className="titlePosts"> <Link to={link}>{title}</Link></h1>
          <p className="textPosts">{excerpt}</p>
          <Link className="buttonPosts" to={link}>Czytaj więcej</Link>
        </div>
      </div>
      </BackgroundImage>
  </article>
)}


const OtherPosts = ({ data, id }) => {
    
    const { edges: posts } = data.allMarkdownRemark
    const filteredPosts = posts
    console.log(posts.filter(post => post.node.id !== id))
  
  var settings = {
    centerMode: true,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return(
  <div className="slickHolder">
  <Slider {...settings}>
    {filteredPosts.map((item, i) => (
              <Item key={item.node.frontmatter.title} date={item.node.frontmatter.date} excerpt={item.node.frontmatter.description === '' ? item.node.excerpt : item.node.frontmatter.description} title={item.node.frontmatter.title} link={item.node.fields.slug} image={item.node.frontmatter.featuredimage.childImageSharp} />
    ))}
  </Slider>
  </div>
)
    }

OtherPosts.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
          edges: PropTypes.array,
        }),
      })
}

export default () => (
    <StaticQuery
      query={graphql`
        query otherPostsQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                
                excerpt(pruneLength: 120)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  description
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredpost
                  featuredimage {
                    childImageSharp {
              fluid(
            maxWidth: 600
            quality: 100
            traceSVG: {
              color: "#A38C88"
              turnPolicy: TURNPOLICY_MAJORITY
              blackOnWhite: true
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
            }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <OtherPosts data={data} count={count} />}
    />
  )
  
